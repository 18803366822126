import { useEffect, useRef } from 'react';
import axios from 'axios';

const useDailyAPI = () => {
  const timerIdRef = useRef(null);

  useEffect(() => {
    const handleDailyAPICall = () => {
      axios.post('https://backendforerpsystem.zbooma.com/add_BlockedDay_toLeaveRequests.php')
        .then(response => {
          console.log('API called successfully:', response.data);
        })
        .catch(error => {
          console.error('Error:', error);
        })
        .finally(() => {
          scheduleNextAPICall(); // Re-schedule after each call
        });
    };

    const scheduleNextAPICall = () => {
      const now = new Date();
      const nextRun = new Date();
      nextRun.setHours(9, 0, 0, 0); // Target time today

      // If today's 9 AM already passed, set to tomorrow
      if (now >= nextRun) {
        nextRun.setDate(nextRun.getDate() + 1);
      }

      const delay = nextRun - now;
      timerIdRef.current = setTimeout(handleDailyAPICall, delay);
    };

    // Immediate check: If past 9 AM, call API now and schedule next
    const now = new Date();
    const today9AM = new Date();
    today9AM.setHours(9, 0, 0, 0);

    if (now >= today9AM) {
      handleDailyAPICall(); // Call immediately if mounted after 9 AM
    } else {
      scheduleNextAPICall(); // Schedule normally
    }

    return () => clearTimeout(timerIdRef.current);
  }, []);
};

export default useDailyAPI;