import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Grid,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Avatar,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
} from "@mui/material";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import CommentIcon from "@mui/icons-material/Comment";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment-timezone";
import Swal from "sweetalert2";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { ThemeProvider, createTheme } from "@mui/material/styles";

// Localizer for the Calendar
const localizer = momentLocalizer(moment);

// Helper hook to get query parameters
const useQuery = () => {
  const location = useLocation();
  return new URLSearchParams(location.search);
};

// Create a dark theme
const darkTheme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#121212",
      paper: "#1E1E1E",
    },
    text: {
      primary: "#FFFFFF",
      secondary: "#BBBBBB",
    },
    primary: {
      main: "#6fd943",
    },
  },
});

// Custom Logger
const logger = (() => {
  const loggedMessages = new Set();
  return {
    log: (message, data = null) => {
      const logKey = data ? `${message} ${JSON.stringify(data)}` : message;
      if (!loggedMessages.has(logKey)) {
        console.log(message, data);
        loggedMessages.add(logKey);
      }
    },
    error: (message, data = null) => {
      const errorKey = data ? `${message} ${JSON.stringify(data)}` : message;
      if (!loggedMessages.has(errorKey)) {
        console.error(message, data);
        loggedMessages.add(errorKey);
      }
    },
    clear: () => loggedMessages.clear(),
  };
})();

const Dashboard = () => {
  const [meetingTimes, setMeetingTimes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userLoading, setUserLoading] = useState(true);
  const [attendanceRecords, setAttendanceRecords] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isRegisteredToday, setIsRegisteredToday] = useState(false);
  const [posts, setPosts] = useState([]);
  const [newComment, setNewComment] = useState({});
  const [user, setUser] = useState({});
  const [likesData, setLikesData] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false); // حالة لتتبع النقر المزدوج

  // start function hidden buttons attendance

  const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
      };

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return isMobile;
  };
  
  const isMobile = useIsMobile();

  // end function hidden buttons attendance

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const getLikesForPost = (postId) => {
    return likesData.filter((like) => like.post_id === String(postId)).length;
  };

  const userHasLikedPost = (postId) => {
    return likesData.some(
      (like) =>
        like.post_id === String(postId) && like.username === user.username
    );
  };

  const location = useLocation(); // Hook to get the location object
  const username = location.state?.username; // Access the username from the state with optional chaining

  useEffect(() => {
    if (!username) {
      logger.error("Username is missing in query parameters.");
      return;
    }

    const todayDate = moment().format("YYYY-MM-DD");
    const fetchUserData = async () => {
      try {
        const response = await axios.get(
          `https://backendforerpsystem.zbooma.com/getemployees.php?username=${username}`
        );
        const userData = response.data.data.find(
          (emp) => emp.username === username
        );
        if (userData) {
          setUser(userData);
        } else {
          logger.error("No user found for username", { username });
        }
      } catch (error) {
        logger.error("Error fetching user data:", error);
      } finally {
        setUserLoading(false);
      }
    };

    const fetchAttendanceRecords = async () => {
      try {
        const response = await axios.get(
          `https://backendforerpsystem.zbooma.com/get_attendance.php?username=${username}&date=${todayDate}`
        );
        const records = response.data.records || [];
        setAttendanceRecords(records);
        setIsRegisteredToday(records.length > 0);
      } catch (error) {
        logger.error("Error fetching attendance records:", error);
      }
    };

    fetchUserData();
    fetchAttendanceRecords();
  }, [username]); // Depend only on username

  useEffect(() => {
    if (!username) return; // Guard against no username

    const fetchPosts = async () => {
      try {
        const response = await axios.get(
          "https://backendforerpsystem.zbooma.com/getPosts.php"
        );
        setPosts(response.data.data);
      } catch (error) {
        logger.error("Error fetching posts:", error);
      }
    };

    const fetchLikesData = async () => {
      try {
        const response = await axios.get(
          "https://backendforerpsystem.zbooma.com/getLikes.php"
        );
        if (response.data.success) {
          setLikesData(response.data.data);
        }
      } catch (error) {
        logger.error("Error fetching likes data:", error);
      }
    };

    fetchPosts();
    fetchLikesData();
  }, [username]); // This effect also depends only on username

  const handleAttendanceSelection = (type) => {
    if (isSubmitting) return; // منع النقر المزدوج إذا كانت العملية قيد التنفيذ

    setIsSubmitting(true); // تعطيل الزر

    const timeZone = "Africa/Cairo";
    const now = moment().tz(timeZone);
    const timeString = now.format("HH:mm:ss");
    const todayDate = now.format("YYYY-MM-DD");
    const todayDay = now.day();

    axios
      .get(
        `https://backendforerpsystem.zbooma.com/getemployees.php?username=${user.username}`
      )
      .then((response) => {
        if (
          response.data.status === "success" &&
          response.data.data.length > 0
        ) {
          const employee = response.data.data.find(
            (emp) => emp.username === user.username
          );

          if (employee.blocked_day === todayDay.toString()) {
            Swal.fire({
              icon: "error",
              title: "تسجيل الحضور غير ممكن",
              text: "لا يحق لك تسجيل الحضور اليوم لأن اليوم هو يوم إجازتك",
            });
            setIsSubmitting(false); // إعادة تمكين الزر
            setDialogOpen(false); // إغلاق الـ Dialog
            return;
          }

          axios
            .get(
              `https://backendforerpsystem.zbooma.com/getleaveRequest.php?username=${user.username}`
            )
            .then((response) => {
              if (response.data.success && response.data.data.length > 0) {
                const todayLeave = response.data.data.find(
                  (leave) =>
                    leave.username === user.username &&
                    leave.start_date === todayDate &&
                    leave.end_date === todayDate &&
                    leave.is_accepted === 1
                );

                if (todayLeave) {
                  Swal.fire({
                    icon: "warning",
                    title: "تسجيل الحضور غير ممكن",
                    text: "لا يحق لك تسجيل الحضور لانك طلبت اجازه",
                  });
                  setIsSubmitting(false); // إعادة تمكين الزر
                  setDialogOpen(false); // إغلاق الـ Dialog
                  return;
                }

                recordAttendance(type, timeString, todayDate);
              } else {
                recordAttendance(type, timeString, todayDate);
              }
            })
            .catch((error) => {
              logger.error("Error checking leave status:", error);
              recordAttendance(type, timeString, todayDate);
            });
        }
      })
      .catch((error) => {
        logger.error("Error fetching employee data:", error);
        recordAttendance(type, timeString, todayDate);
      });
  };

  const recordAttendance = (type, timeString, todayDate) => {
    axios
      .get(
        `https://backendforerpsystem.zbooma.com/getPermissionsy.php?username=${user.username}`
      )
      .then((permissionResponse) => {
        const permissionsToday = permissionResponse.data.data.filter(
          (p) =>
            p.username === user.username &&
            p.is_accepted === 1 &&
            moment(p.start_time, "HH:mm:ss").isSame(todayDate, "day")
        );

        let adjustedStartTime = moment(timeString, "HH:mm:ss");

        permissionsToday.forEach((permission) => {
          let startTime = moment(permission.start_time, "HH:mm:ss");
          let endTime = moment(permission.end_time, "HH:mm:ss");
          let duration = moment.duration(endTime.diff(startTime));

          if (
            adjustedStartTime.isSameOrAfter(startTime) &&
            adjustedStartTime.isSameOrBefore(endTime)
          ) {
            adjustedStartTime.subtract(duration);
          }
        });

        const finalStartTime = adjustedStartTime.format("HH:mm:ss");

        return axios.post(
          "https://backendforerpsystem.zbooma.com/record_attendance.php",
          {
            username: user.username,
            type,
            time: finalStartTime,
            date: todayDate,
          }
        );
      })
      .then((response) => {
        if (response.data.success) {
          const now = new Date();
          const arabicTime = now.toLocaleTimeString("ar-EG", {
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            timeZone: "Africa/Cairo",
          });
          Swal.fire({
            icon: "success",
            title: "تم تسجيل الحضور",
            text: `تم تسجيل الحضور في الساعة ${arabicTime}`,
          });

          return axios.get(
            `https://backendforerpsystem.zbooma.com/get_attendance.php?username=${user.username}&date=${todayDate}`
          );
        } else {
          logger.error("Failed to record attendance:", response.data.message);
          throw new Error("Failed to record attendance");
        }
      })
      .then((res) => {
        const records = res.data.records || [];
        setAttendanceRecords(records);
        setIsRegisteredToday(records.length > 0);
      })
      .catch((err) => {
        logger.error("Error during the attendance process:", err);
      })
      .finally(() => {
        setIsSubmitting(false); // إعادة تمكين الزر بعد الانتهاء من العملية
        setDialogOpen(false); // إغلاق الـ Dialog بعد الانتهاء من العملية
      });
  };
  const handleLikePost = (postId) => {
    if (!user || !user.username || !postId) {
      logger.error("Missing required fields for liking the post", {
        postId,
        username: user?.username,
      });
      return;
    }

    const formData = new URLSearchParams();
    formData.append("postId", postId);
    formData.append("username", user.username);

    axios
      .post("https://backendforerpsystem.zbooma.com/likePost.php", formData)
      .then((response) => {
        if (response.data.success) {
          setPosts((prevPosts) =>
            prevPosts.map((post) =>
              post.id === postId
                ? {
                    ...post,
                    hasLiked: true,
                    likes: parseInt(post.likes || 0) + 1,
                  }
                : post
            )
          );
        } else {
          logger.error("Error liking post:", response.data.message);
        }
      })
      .catch((error) => {
        logger.error("Error liking post:", error);
      });
  };

  const handleCommentChange = (event, postId) => {
    setNewComment((prevComments) => ({
      ...prevComments,
      [postId]: event.target.value,
    }));
  };

  const handleAddComment = (postId) => {
    const commentText = newComment[postId] || "";

    if (!user || !user.username || !postId || !commentText.trim()) {
      logger.error("Missing fields for adding a comment", {
        postId,
        username: user?.username,
        commentText,
      });
      return;
    }

    const formData = new URLSearchParams();
    formData.append("postId", postId);
    formData.append("username", user.username);
    formData.append("commentText", commentText);

    axios
      .post("https://backendforerpsystem.zbooma.com/addComment.php", formData)
      .then((response) => {
        if (response.data.success) {
          setPosts((prevPosts) =>
            prevPosts.map((post) =>
              post.id === postId
                ? {
                    ...post,
                    comments: [
                      ...post.comments,
                      {
                        id: new Date().getTime(),
                        comment_text: commentText,
                        username: user.username,
                        created_at: new Date().toISOString(),
                      },
                    ],
                  }
                : post
            )
          );
          setNewComment((prevComments) => ({
            ...prevComments,
            [postId]: "",
          }));
        } else {
          logger.error("Error adding comment:", response.data.message);
        }
      })
      .catch((error) => {
        logger.error("Error adding comment:", error);
      });
  };

  const currentTime = moment();
  const isWithinAttendanceHours = currentTime.isBetween(
    moment().startOf("day").add(8, "hours"),
    moment().startOf("day").add(17, "hours")
  );

  return (
    <ThemeProvider theme={darkTheme}>
      <Box p={3} style={{ direction: "rtl" }}>
        <Grid container spacing={3} style={{ direction: "rtl" }}>
          {/* User Profile and Attendance */}
          <Grid item xs={12}>
            <Paper
              elevation={3}
              style={{
                padding: "16px",
                marginBottom: "16px",
                backgroundColor: darkTheme.palette.background.paper,
                color: darkTheme.palette.text.primary,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "10px",
              }}
            >
              {userLoading ? (
                <CircularProgress />
              ) : (
                <>
                  <Avatar
                    src={`https://backendforerpsystem.zbooma.com/${user.photo}`}
                    alt={user.name}
                    style={{ width: 60, height: 60 }}
                  />
                  <Typography
                    variant="h6"
                    style={{
                      fontFamily: "Cairo, sans-serif",
                      textAlign: "center",
                    }}
                  >
                    {user.name}
                  </Typography>

                  {isWithinAttendanceHours &&
                    !isRegisteredToday &&
                    !isMobile && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleOpenDialog}
                        style={{
                          width: "100%",
                          fontFamily: "Cairo, sans-serif",
                        }}
                      >
                        تسجيل الحضور
                      </Button>
                    )}
                  {attendanceRecords.length > 0 && (
                    <Box mt={2} style={{ textAlign: "center" }}>
                      <Typography
                        variant="h6"
                        style={{ fontFamily: "Cairo, sans-serif" }}
                      >
                        سجلات الحضور لليوم
                      </Typography>
                      <TableContainer
                        component={Paper}
                        style={{
                          backgroundColor: darkTheme.palette.background.paper,
                        }}
                      >
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell
                                align="right"
                                style={{
                                  fontFamily: "Cairo, sans-serif",
                                  color: darkTheme.palette.text.primary,
                                }}
                              >
                                نوع الحضور
                              </TableCell>
                              <TableCell
                                align="right"
                                style={{
                                  fontFamily: "Cairo, sans-serif",
                                  color: darkTheme.palette.text.primary,
                                }}
                              >
                                الوقت
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {attendanceRecords.map((record, index) => (
                              <TableRow key={index}>
                                <TableCell
                                  align="right"
                                  style={{
                                    fontFamily: "Cairo, sans-serif",
                                    color: darkTheme.palette.text.primary,
                                  }}
                                >
                                  {record.type}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  style={{
                                    fontFamily: "Cairo, sans-serif",
                                    color: darkTheme.palette.text.primary,
                                  }}
                                >
                                  {record.time}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  )}
                </>
              )}
            </Paper>
          </Grid>

          {/* Calendar Section */}
          <Grid style={{ width: "100%" }}>
            <Typography
              variant="h5"
              style={{
                fontFamily: "Cairo, sans-serif",
                color: darkTheme.palette.primary.main,
                textAlign: "center",
                margin: "16px",
              }}
            >
              التقويم الشهري
            </Typography>
            <Paper
              elevation={3}
              style={{
                padding: "16px",
                marginBottom: "16px",
                backgroundColor: darkTheme.palette.background.paper,
              }}
            >
              <Calendar
                localizer={localizer}
                events={meetingTimes.map((meeting) => ({
                  title: meeting.title,
                  start: new Date(meeting.start),
                  end: new Date(meeting.end),
                }))}
                startAccessor="start"
                endAccessor="end"
                style={{
                  height: 500,
                  backgroundColor: darkTheme.palette.background.paper,
                  color: darkTheme.palette.text.primary,
                }}
                dayPropGetter={(date) => ({
                  style: {
                    backgroundColor: moment(date).isSame(new Date(), "day")
                      ? "#6fd943"
                      : darkTheme.palette.background.default,
                    color: darkTheme.palette.text.primary,
                  },
                })}
              />
            </Paper>
          </Grid>

          {/* Posts Section */}
          <Grid style={{ width: "100%", marginTop: "70px" }}>
            <Typography
              variant="h4"
              style={{
                fontFamily: "Cairo, sans-serif",
                color: darkTheme.palette.primary.main,
                marginBottom: "16px",
                textAlign: "center",
              }}
            >
              المنشورات المهنية
            </Typography>
            {posts.map((post) => (
              <Paper
                key={post.id}
                elevation={3}
                style={{
                  padding: "24px",
                  marginBottom: "24px",
                  borderRadius: "12px",
                  backgroundColor: darkTheme.palette.background.paper,
                  color: darkTheme.palette.text.primary,
                }}
              >
                {post.post_image_url && (
                  <Box
                    style={{
                      width: "100%",
                      height: "400px",
                      overflow: "hidden",
                      borderRadius: "12px",
                      marginBottom: "16px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={`https://backendforerpsystem.zbooma.com/${post.post_image_url}`}
                      alt="Post"
                      style={{
                        width: "auto",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </Box>
                )}
                <Typography
                  variant="h5"
                  style={{
                    marginBottom: "12px",
                    fontWeight: "bold",
                    fontFamily: "Cairo",
                  }}
                >
                  {post.post_title}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    marginBottom: "16px",
                    lineHeight: "1.6",
                    fontFamily: "Cairo",
                    color: darkTheme.palette.text.secondary,
                  }}
                >
                  {post.post_content}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={{
                    marginBottom: "16px",
                    color: darkTheme.palette.text.secondary,
                  }}
                >
                  {new Date(post.created_at).toLocaleString("en-US", {
                    hour12: true,
                  })}
                </Typography>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography
                    style={{
                      marginRight: "8px",
                      color: darkTheme.palette.text.primary,
                    }}
                  >
                    {getLikesForPost(post.id)}
                  </Typography>
                  <Box display="flex" alignItems="center">
                    <IconButton
                      onClick={() => handleLikePost(post.id)}
                      style={{
                        color: userHasLikedPost(post.id)
                          ? "gray"
                          : darkTheme.palette.primary.main,
                      }}
                      disabled={userHasLikedPost(post.id)}
                    >
                      <ThumbUpIcon />
                      <Typography
                        style={{
                          marginLeft: "4px",
                          color: darkTheme.palette.primary.main,
                        }}
                      >
                        {getLikesForPost(post.id)}
                      </Typography>
                    </IconButton>
                    <IconButton
                      style={{ color: darkTheme.palette.primary.main }}
                    >
                      <CommentIcon />
                    </IconButton>
                  </Box>
                  <Box display="flex" alignItems="center" flexGrow={1}>
                    <TextField
                      value={newComment[post.id] || ""}
                      onChange={(event) => handleCommentChange(event, post.id)}
                      variant="outlined"
                      size="small"
                      placeholder="Add a comment..."
                      style={{
                        flexGrow: 1,
                        marginRight: "8px",
                        backgroundColor: darkTheme.palette.background.default,
                        borderRadius: "8px",
                        color: darkTheme.palette.text.primary,
                      }}
                      InputProps={{
                        style: { color: darkTheme.palette.text.primary },
                      }}
                    />
                    <Button
                      onClick={() => handleAddComment(post.id)}
                      variant="contained"
                      color="primary"
                      style={{
                        backgroundColor: darkTheme.palette.primary.main,
                        marginLeft: "8px",
                      }}
                    >
                      Comment
                    </Button>
                  </Box>
                </Box>
                <Box mt={2}>
                  {post.comments.map((comment, index) => (
                    <Paper
                      key={index}
                      style={{
                        padding: "12px",
                        marginTop: "8px",
                        backgroundColor: darkTheme.palette.background.default,
                        borderRadius: "8px",
                        color: darkTheme.palette.text.primary,
                      }}
                    >
                      <Typography
                        variant="body2"
                        style={{ color: darkTheme.palette.text.primary }}
                      >
                        {comment.comment_text}
                      </Typography>
                      <Typography
                        variant="caption"
                        style={{ color: darkTheme.palette.text.secondary }}
                      >
                        - {comment.username} on{" "}
                        {new Date(comment.created_at).toLocaleString()}
                      </Typography>
                    </Paper>
                  ))}
                </Box>
              </Paper>
            ))}
          </Grid>
        </Grid>

        {/* Attendance Dialog */}
        <Dialog
          open={dialogOpen}
          onClose={handleCloseDialog}
          aria-labelledby="attendance-dialog-title"
        >
          <DialogTitle
            id="attendance-dialog-title"
            style={{ fontFamily: "Cairo, sans-serif", textAlign: "center" }}
          >
            تسجيل الحضور
          </DialogTitle>
          <DialogContent
            style={{ fontFamily: "Cairo, sans-serif", textAlign: "center" }}
          >
            اختر نوع الحضور
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => handleAttendanceSelection("الحضور من المنزل")}
              disabled={isSubmitting} // تعطيل الزر أثناء التنفيذ
              color="primary"
              variant="outlined"
              style={{ fontFamily: "Cairo, sans-serif", width: "100%" }}
            >
              الحضور من المنزل
            </Button>
            <Button
              onClick={() => handleAttendanceSelection("الحضور من الموقع")}
              disabled={isSubmitting} // تعطيل الزر أثناء التنفيذ
              color="primary"
              variant="contained"
              style={{ fontFamily: "Cairo, sans-serif", width: "100%" }}
            >
              الحضور من الموقع
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </ThemeProvider>
  );
};

export default Dashboard;
